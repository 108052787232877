@mixin xsm {
    @media only screen and (min-width: 360px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (min-width: 560px) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: 760px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: 972px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: 1150px) {
        @content;
    }
}

@mixin xll {
    @media only screen and (min-width: 1400px) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (min-width: 1636px) {
        @content;
    }
}
