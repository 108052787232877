@import "../../css-config/mixins.scss";

.image_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem 1rem 0rem 1.25rem;
    @include xl {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 2rem;
        padding: 2rem 1rem 0rem 1.25rem;
    }
    .first_col {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        @include sm {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }

        @include xl {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }
        
        .item {
            border: 1px solid yellow;
            display: flex;
            flex-direction: column;
            width: 21.3rem;
            padding-bottom: 1rem;
            gap: 0.625rem;
            text-align: center;
            img {
                width: 100%;
                transition: all 0.5s linear;
            }
            img:hover {
                transform:scale(0.9);
            }
        }
    }

    .sec_col {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        @include sm {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }

        @include xl {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }
        .item {
            border: 1px solid yellow;
            display: flex;
            flex-direction: column;
            width: 21.3rem;
            gap: 0.725rem;
            padding-bottom: 0.625rem;
            text-align: center;
            img {
                width: 100%;
                height: 100%;
                transition: all 0.5s linear;
            }
            img:hover {
                transform:scale(0.9);
            }
        }
    }
}