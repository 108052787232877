@import "../../css-config/mixins.scss";

.event_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem 1rem;
  @include xl {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 5rem 4rem;
  }
  @include xll {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 5rem 4rem;
  }
  h1 {
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 56px;
    line-height: 62px;
    color: #000000;
    @include xl {
      font-family: "Work Sans";
      font-weight: 700;
      font-size: 64px;
      line-height: 68px;
      color: #000000;
    }
    @include xll {
      font-family: "Work Sans";
      font-weight: 700;
      font-size: 64px;
      line-height: 68px;
      color: #000000;
    }
  }
  .image_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    @include xl {
      gap: 3rem;
    }
  }

  .left_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.5s linear;
    @include xl {
      width: 47%;
      height: 470px;
    }
    @include xll {
      width: 48%;
      height: 470px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card_body {
      width: 100%;
      height: 100%;
      top: 0;
      right: -100%;
      position: absolute;
      background: #1f3d4738;
      backdrop-filter: blur(5px);
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 1s;
      .card_title {
        font-family: "Lexend";
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
        text-decoration: none !important;
        color: #ffffff;
        @include xl {
          font-family: "Lexend";
          font-weight: 600;
          font-size: 40px;
          line-height: 50px;
          text-transform: uppercase;
          text-decoration: none !important;
          color: #ffffff;
        }
        @include xll {
          font-family: "Lexend";
          font-weight: 600;
          font-size: 40px;
          line-height: 50px;
          text-transform: uppercase;
          text-decoration: none !important;
          color: #ffffff;
        }
      }
    }
  }
  .left_img:hover .card_body {
    right: 0;
  }
}
