@import "../../css-config/mixins.scss";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ourstory_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #D9D9D9;
    @include xl {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 5rem;
        background: #D9D9D9;
    }
    @include xll {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 5rem;
        background: #D9D9D9;
    }
    .left_image{
        width: 100%;
        height: 100%;
        @include xl {
            width: 50%;
            height: 605px;
        }
        @include xll {
            width: 43%;
            height: 600px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    .right_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding: 1rem;
        padding-bottom: 2rem;
        @include xl {
            width: 50%;
            padding: 3rem 0;
            gap: 2rem;
        }
        @include xll {
            width: 50%;
            padding: 0rem 0;
            gap: 2rem;
        }
        h1 {
            font-family: 'Work Sans';
            font-weight: 700;
            font-size: 56px;
            line-height: 62px;
            color: #000000;
            @include xl {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                color: #000000;
            }
            @include xll {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                color: #000000;
            }
        }
        p {
            width: 100%;
            font-family: 'Work Sans';
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: #000000;
            @include xl {
                width: 100%;
                padding-right: 4rem;
                font-family: 'Work Sans';
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
            }
            @include xll {
                width: 80%;
                padding-right: 0rem;
                font-family: 'Work Sans';
                font-weight: 400;
                font-size: 20px;
                line-height: 32px;
            }
        }
        .contact_btn {
            width: 215px;
            height: 52px;
            background: #F0C447;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Work Sans';
            font-weight: 600;
            font-size: 25px;
            text-decoration: none;
            line-height: 34px;
            color: #000000;
            margin-top: 0.35rem;
            @include xl {
                width: 229px;
                height: 52px;
                background: #F0C447;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Work Sans';
                font-weight: 600;
                font-size: 25px;
                text-decoration: none;
                line-height: 34px;
                color: #000000;
                margin-top: 0.35rem;
                margin-bottom: 1rem;
            }
            @include xll {
                width: 229px;
                height: 52px;
                background: #F0C447;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Work Sans';
                font-weight: 600;
                font-size: 25px;
                text-decoration: none;
                line-height: 34px;
                color: #000000;
                margin-top: 0.35rem;
            }
        }
        .contact_btn:hover {
            background: #fbd469;
        }

        a{
            text-decoration: none;
        }
    }
}