@import "../../css-config/mixins.scss";

.view_btn {
    width: 100%;
    display: flex;
        align-items: center;
        justify-content: center;
    a {
        text-decoration: none;
        font-family: 'Work Sans';
        font-weight: 600;
        font-size: 17px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 158px;
        height: 48px;
        border: 2px solid #9FA68C;
        border-radius: 16px;
        color: black;
        background: #F0C447;
        margin-top: 2rem;
    }
    a:hover {
        background: #fbd469;
    }
}