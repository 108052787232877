@import "../../css-config/mixins.scss";

.footer_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 1rem;
    gap: 3rem;
    background:  rgba(240, 196, 71, 0.49);
    @include xl {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 4rem;
        gap: 2rem;
    }
    .footer_nav-menu {
        display: flex;
        gap: 2rem;
        li a {
            font-family: 'Work Sans';
            text-decoration: none;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #36392D;
            transition: all 0.4s linear;
        }
        li a:hover {
            font-weight: 500;
            color: #000000;
        }
    }

    .social_icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1.75rem;
        transform: translateX(-1.35rem);
        h1 {
            font-family: 'Work Sans';
            font-weight: 900;
            font-size: 32px;
            line-height: 37px;
            color: #36392D;
        }
        .icons {
            display: flex;
            align-items: center;
            gap: 2rem;
            .icon {
                color: #36392D;
                font-size: 33.3px;
                cursor: pointer;
                transition: all .2s;
            }
            .icon:hover {
                color: #000000;
            }
        }
    }

    .contact {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @include xl {
            width: 20%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
        .email {
            background: transparent;
            outline: none;
            border: none;
            border-bottom: 2px solid #36392D;
            padding: 0.125rem 0;
            font-family: 'Work Sans';
            font-size: 1rem;
            color: #36392D;
        }
        .submit {
            width: 120px !important;
            height: 40px;
            background: transparent;
            outline: none;
            border: none;
            border: 1px solid #36392D;
            border-radius: 5px;
            font-family: 'Work Sans';
            font-size: 1.125rem;
            font-weight: 400;
            color: #36392D;
            cursor: pointer;
            @include xl {
                width: 100px !important;
                height: 30px;
                background: transparent;
                outline: none;
                border: none;
                border: 1px solid #36392D;
                border-radius: 5px;
                font-family: 'Work Sans';
                font-size: 1rem;
                font-weight: 400;
                color: #36392D;
                cursor: pointer;
            }
        }
        .submit:hover {
            border: 2px solid #000000;
            color: #000000;
            font-weight: 500;
        }
    }
}