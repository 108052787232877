@import "../../css-config/mixins.scss";

.menu_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 3rem 1rem 0rem 1rem;
    @include xl {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        padding: 5rem 1.75rem 0rem 1.75rem;
    }
    @include xll {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        padding: 4rem 1.75rem 0rem 1.75rem;
    }
    .top_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        h1 {
            font-family: 'Work Sans';
            font-weight: 700;
            font-size: 56px;
            line-height: 62px;
            color: #000000;
            @include xl {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                color: #000000;
            }
            @include xll {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                color: #000000;
            }
        }
        p {
            width: 100%;
            font-family: 'Work Sans';
            font-weight: 400;
            font-size: 19px;
            line-height: 140%;
            text-align: center;
            color: #36392D;
            @include xl {
                width: 60%;
                font-family: 'Work Sans';
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                text-align: center;
                color: #36392D;
            }
            @include xll {
                width: 60%;
                font-family: 'Work Sans';
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                text-align: center;
                color: #36392D;
            }
        }
    }
    
}