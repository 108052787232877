@import "../../css-config/mixins.scss";

.navbar_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.35rem;
    @include xl {
        width: 73%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
    }
    @include xll {
        width: 68%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
    }
    .logo img {
        width: 179.44px;
        height: 83.13px;
    }

    .nav_menu {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        @include xl {
            display: flex;
            align-items: center;
            gap: 4.35rem;
        }
        @include xll {
            display: flex;
            align-items: center;
            gap: 4.35rem;
        }
        li a {
            text-decoration: none;
            font-family: 'Work Sans';
            font-weight: 500;
            font-size: 17px;
            line-height: 125.8%;
            color: #FFFFFF; 
            @include xl {
                text-decoration: none;
                font-family: 'Work Sans';
                font-weight: 400;
                font-size: 17px;
                line-height: 125.8%;
                color: #FFFFFF; 
            }
        }
        li a:after {
            display: block;
            content: '';
            width: 0;
            height: 1px;
            background: #F0C447;
            top: 0;
            bottom: 0;
            margin-top: 0.025rem;
            transition: all 0.4s ease-in-out;
        }
        li a:hover:after {
            width: 70%;
        }
        li a:hover {
            color: #F0C447;
        }
        
    }
}

