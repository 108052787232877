@import "../../css-config/mixins.scss";

.contact_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 1rem 3rem 1rem;
    @include xl {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1rem 4rem 4rem 4rem;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.75rem;
        h1 {
            font-family: "Work Sans";
            font-weight: 700;
            font-size: 56px;
            line-height: 62px;
            color: #000000;
            margin-bottom: 0rem;
            @include xl {
                font-size: 64px;
                line-height: 68px;
                color: #000000;
                margin-bottom: 1.35rem;
            }
            @include xll {
                font-size: 64px;
                line-height: 68px;
                color: #000000;
                margin-bottom: 1.35rem;
            }
        }
        input {
            width: 115%;
            border: none;
            outline: none;
            border-bottom: 1px solid #000000;
            background: none;
            padding: 0.625rem 0;
            font-family: "Work Sans";
            font-size: 1rem;
            @include xl {
                width: 180%;
                border: none;
                outline: none;
                border-bottom: 1px solid #000000;
                background: none;
                padding: 0.625rem 0;
                font-family: "Work Sans";
                font-size: 1rem;
            }
            @include xll {
                width: 180%;
                border: none;
                outline: none;
                border-bottom: 1px solid #000000;
                background: none;
                padding: 0.625rem 0;
                font-family: "Work Sans";
                font-size: 1rem;
            }
        }
        #message {
            width: 115%;
            border: none;
            outline: none;
            resize: none;
            border-bottom: 1px solid #000000;
            background: none;
            padding: 0.625rem 0;
            font-family: "Work Sans";
            font-size: 1rem;
            @include xl {
                width: 180%;
                border: none;
                outline: none;
                resize: none;
                border-bottom: 1px solid #000000;
                background: none;
                padding: 0.625rem 0;
                font-family: "Work Sans";
                font-size: 1rem;
            }
            @include xll {
                width: 180%;
                border: none;
                outline: none;
                resize: none;
                border-bottom: 1px solid #000000;
                background: none;
                padding: 0.625rem 0;
                font-family: "Work Sans";
                font-size: 1rem;
            }
        }

        #submit {
            width: 170px;
            height: 52px;
            background: #F0C447;
            border-radius: 10px;
            border: none;
            font-family: 'Work Sans';
            font-weight: 600;
            font-size: 19px;
            line-height: 34px;
            color: #000000;
            margin-top: 1rem;
            cursor: pointer;
            @include xl {
                width: 170px;
                height: 52px;
                background: #F0C447;
                border-radius: 10px;
                border: none;
                font-family: 'Work Sans';
                font-weight: 600;
                font-size: 20px;
                line-height: 34px;
                color: #000000;
                margin-top: 1rem;
                cursor: pointer;
            }
            @include xll {
                width: 170px;
                height: 52px;
                background: #F0C447;
                border-radius: 10px;
                border: none;
                font-family: 'Work Sans';
                font-weight: 600;
                font-size: 20px;
                line-height: 34px;
                color: #000000;
                margin-top: 1rem;
                cursor: pointer;
            }
        }
        #submit:hover {
            background: #fbd469;
        }
    }
}