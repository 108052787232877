@import "../../css-config/mixins.scss";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.hero_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    padding: 1.55rem 1rem ;
    background: url(../../assets/hero_bg.png) no-repeat;
    background-position: center;
    background-size: cover;
    @include xl {
        width: 100%;
        height: 100vh;
        gap: 3rem;
        padding: 1rem 4rem 0.03rem 4rem;
    }

    @include xll {
        width: 100%;
        height: 100vh;
        gap: 3rem;
        padding: 1rem 4rem 0.03rem 4rem;
    }

    .text_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.825rem;
        @include xl {
            gap: 1.25rem;  
        }
        @include xll {
            gap: 0.825rem; 
        }
        h1 span {      
            font-family: 'Work Sans';
            font-weight: 700;
            font-size: 75px;
            line-height: 113.8%;
            color: #F0C447;
            @include xl {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 80px;
                line-height: 123.8%;
                color: #F0C447;
            }
            @include xll {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 95px;
                line-height: 123.8%;
                color: #F0C447;
            }
        }
        h1 {
            font-family: 'Work Sans';
            font-weight: 700;
            font-size: 75px;
            line-height: 113.8%;
            color: #FFFFFF;
            @include xl {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 80px;
                line-height: 123.8%;
                color: #F0C447;
            }
            @include xll {
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 95px;
                line-height: 123.8%;
                color: #F0C447;
            }
        }
        p {
            font-family: 'Work Sans';
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            line-height: 34px;
            color: #FFFFFF;
            @include xl {
                font-family: 'Work Sans';
                font-style: italic;
                font-weight: 400;
                font-size: 27px;
                line-height: 39px;
                color: #FFFFFF;
            }
            @include xll {
                font-family: 'Work Sans';
                font-style: italic;
                font-weight: 400;
                font-size: 30px;
                line-height: 39px;
                color: #FFFFFF;
            }
        }
        .order_btn {
            text-decoration: none;
            width: 215px;
            height: 52px;
            border: 1px solid #D9D9D9;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Work Sans';
            font-weight: 700;
            font-size: 30px;
            line-height: 39px;
            color: #FFFFFF;
            cursor: pointer;
            margin-top: 2rem;
            transition: all .4s linear;
            @include xl {
                width: 229px;
                height: 52px;
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 28px;
                line-height: 39px;
                color: #FFFFFF;
            }
            @include xll {
                width: 229px;
                height: 52px;
                font-family: 'Work Sans';
                font-weight: 700;
                font-size: 33px;
                line-height: 39px;
                color: #FFFFFF;
            }
        }
        .order_btn:hover {
           transform: rotateZ(5deg);
           border: 1px solid #F0C447;
        }
    }

    .social_link {
        width: 280px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.575rem;
        @include xl {
            width: 429px;
            height: 55px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 0 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.46rem;
            padding-top: 0.675rem;
        }
        @include xll {
            width: 429px;
            height: 52px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 0 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.36rem;
            padding-top: 0.675rem;
        }
        .icon {
            color: #000000;
            font-size: 28px;
            cursor: pointer;
            transition: all 0.4s linear;
            @include xl {
                font-size: 30.3px;
            }
            @include xll {
                font-size: 33.3px;
            }
        }
        .icon:hover {
            color: #F0C447;
            transform: translateX(5px);
        }
    }
}