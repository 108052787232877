@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
}

code {
  font-family: 'Work Sans', sans-serif;
}
